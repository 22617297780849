export default {
  blogerName: 'LUCK',
  socialsList: [
    {
      name: 'twitch',
      url: 'http://www.twitch.tv/nezhenkapirozhenka'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@nezhenka_pirozhenka/'
    },
    {
      name: 'telegram',
      url: 'https://t.me/fartandudacha'
    },
    {
      name: 'discord',
      url: 'https://discord.gg/KFRfGm544w'
    },
    {
      name: 'linktree',
      url: 'https://linkr.bio/Nezha.com'
    }
  ],
  projects: [
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/c41b5af31',
      gameTitle: 'Royal High-Road (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c6b05f42e',
      gameTitle: 'All Lucky Clover 5 (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c016b3d40',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/ceb9b6d32',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c1b3866c6',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cd6502445',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/ce1dba9ac',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c8dbfa306',
      gameTitle: 'Mechanical Clover (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/cda39be43',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>LUCK50</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'LUCK50',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
